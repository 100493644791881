<script>

export default {
  name: "proof-view-file-thumbnail",
  props: ["file", "activeFileId", "showTitle", "showHorizontal", "isMobile", "disabled"],
  methods: {
    selected () {
      this.$emit('selected', this.file);
    }
  },
  computed: {
    thumbnailFileKey() {
      if (this.file.thumbnailFileKey.startsWith('static')) {
        return '/' + this.file.thumbnailFileKey;
      }
      if (this.file.thumbnailFileKey.startsWith('public/icon/')) {
        return this.file.thumbnailFileKey.replace('public/icon/', '/img/');
      }
      return this.file.thumbnailFileKey;
    }
  }
}
</script>

<template>
    <a @click.prevent="selected" :class="{'d-flex align-items-center' : !showHorizontal}" class="file-sidebar-link gap-3 text-decoration-none px-2 py-2 rounded-3" href="">
      <div class="thumb-icon position-relative">
        <div v-if="!file.viewed" class="active-dot-alt toolbar-active-dot rounded-circle bg-danger position-absolute start-0 top-0 translate-middle"></div>
        <span :style="{background: `url(${thumbnailFileKey})`, backgroundSize: 'cover', width: '40px', height: '40px'}"
              :class="activeFileId===file.id && 'active-btn'"
              class="d-inline-flex align-items-center justify-content-center p-2 bg-ashore-white rounded-3 overflow-hidden shadow-base custom-thumbnail">
        </span>
        <span v-if="file.proofFileAnnotations.length>0" class="w-20 h-20 toolbar-edge rounded-circle bg-success-100 text-success-800 position-absolute d-flex align-items-center justify-content-center fs-12">{{file.proofFileAnnotations.length}}</span>
      </div>
      <span v-cloak v-show="showTitle" v-if="!isMobile" class="text-gray-600 text-nowrap">{{file.name}}</span>
    </a>
</template>

<style scoped>
.custom-thumbnail {
  background-size: contain !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}
.active-btn{
  box-shadow: 0px 0px 0px 2px #448BE2 !important;
}
.file-sidebar-link:hover{
  background-color: #F4F4F6;
}
.toolbar-edge{
  right: -3.898px;
  bottom: -4px;
}
</style>
