<script>
import Vue from "vue";
import AudioVisual from "vue-audio-visual";
import Redactor from "../form/redactor";
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue';
import VueSlider from 'vue-slider-component';
// import 'vue-slider-component/theme/default.css';
import Wavesurfer from '../wavesurfer/wavesurfer';

Vue.use(AudioVisual);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

export default {
  components: {
    Redactor,
    VueSlider,
    Wavesurfer,
  },
  props: {
    fileId: {
      type: Number
    },
    src: {
      required: true,
      type: String,
    },
    contentType: {
      required: true,
      type: String,
    },
    docType: {
      required: true,
      type: String,
    },
    poster: {
      required: true,
      type: String,
    },
    annotations: {
      required: true,
      type: Array,
    },
    curWidth: {
      required: true,
      type: Number,
    },
    curHeight: {
      required: true,
      type: Number,
    },
    editingAnnotationId: {
      required: true,
    },
    isMobile: {
      required: true,
    },
    isRecording: {
      default: false,
    },
    zoomMode: {}
  },
  data() {
    return {
      state: -1,
      player: null,
      playProgressInterval: null,
      progressBarWidth: 0,
      lastTimeUpdateSecond: 0,
      volumeValue: 50,
      playerMuted: false,
      tooltipXPosition: 0,
      isDarkMode: true,
      movingProgressBar: false
    };
  },
  watch: {
    src: async function (o, n) {
      // console.log(o,n);
      // console.log(this.$refs.videoHtml);
      await this.pause();
      this.goTo(0);
      if (this.$refs.videoHtml !== undefined) {
        await this.update();
      }
    },
    curWidth: function () {
      this.$nextTick(async () => await this.update())
    },
    volumeValue: function () {
      this.player.volume = this.volumeValue / 100;
      this.setMute(this.volumeValue == 0);
    }
  },
  mounted() {
    if (this.isMobileInDarkMode) {
      console.log('Dark mode disabled for iOS devices');
    }
  },
  computed: {
    isIOS: function () {
      return this.$A.getMobileOperatingSystem() === "iOS";
    },
    isFullScreenVideo: function() {
      return _.get(this.$refs.videoHtml, 'isFullScreen', false);
    },
    isMobileInDarkMode: function () {
      const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (this.isMobile && this.isIOS) {
        if (prefersDarkMode) {
          this.$data.isDarkMode = false;
        }
        return false;
      }
      return prefersDarkMode;
    },
    isAudio: function () {
      return this.docType === "audio";
    },
    isVideo: function () {
      return this.docType === "video";
    },
  },
  methods: {
    getTimeFormatFromSeconds(t) {
      const minutes = Math.floor(t / 60);
      const seconds = Math.floor(t % 60);
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    },
    async update() {
      this.$forceUpdate();
      await this.trackProgress();
    },
    emitNewCommentEvent() {
      if (this.isRecording) {
        return;
      }
      this.$emit("oncommentcreated", {
        curTime: this.getCurPlayerTime(),
        offsetX: this.progressBarWidth,
      });
    },
    getCurPlayerTime: function () {
      return Math.round(this.player.currentTime * 100) / 100;
    },
    getPlayerDurationTime: function () {
      return Math.round(this.player.duration * 100) / 100;
    },
    goTo: function (time) {
      if (!this.player) return;
      let p = time / this.player.duration;
      this.player.currentTime = time;
      let decimalTime = Math.round(time * 100) / 100;
      let holderOffsetWidth = _.get(this, '$refs.progressHolder.offsetWidth', 0);
      this.progressBarWidth = p * holderOffsetWidth;
      if (this.lastTimeUpdateSecond !== decimalTime) {
        this.lastTimeUpdateSecond = decimalTime;
        this.$root.$emit("player:progress:change", decimalTime);
        window.shared = {
          curTime: decimalTime,
        };
      }
      this.setTooltipXPosition(p);
    },
    setTooltipXPosition: function (p) {
      if (!this.$refs.audioWaveHolder) return;
      let rect = this.$refs.audioWaveHolder.getBoundingClientRect();
      let offset = 50 / rect.width;
      this.tooltipXPosition = (p - offset) * 100;
    },
    setMute(value) {
      this.player.muted = value;
      this.playerMuted = this.player.muted;
    },
    async play() {
      if (this.player !== undefined && this.player !== null) {
        let holderOffsetWidth = _.get(this, '$refs.progressHolder.offsetWidth', null);
        if (holderOffsetWidth === null) return;
        this.player.currentTime = (this.progressBarWidth/holderOffsetWidth) * this.player.duration;
        await this.player.play();
      }
      this.state = 1;
    },
    pause: async function () {
      if (this.player !== undefined && this.player !== null) {
        await this.player.pause();
      }
      this.state = 0;
    },
    reset: async function () {
      if (this.player !== undefined && this.player !== null) {
        this.player.currentTime = 0;
        await this.player.pause();
      }
      this.state = 0;
    },
    toggleMute: function () {
      if (this.player !== undefined && this.player !== null) {
        this.setMute(!this.player.muted);
      }
    },
    // listen event
    onPlayerLoadStart(event) {
      this.player = event.target;
      this.player.volume = 0.5;
    },
    onMetaDataLoaded(event) {
      this.player = event.target;
      this.setMute(false);
      this.state = 0;
      this.$scrollTo("body");
      this.$emit("onplayerloaded", event);
    },
    onPlayerLoaded(event) {
      // this.$emit('onplayerloaded', event)
      // console.log('player loaded!', event);
    },
    async onPlayerPlay(event) {
      await this.trackProgress();
    },
    async onPlayerPause(event) {
      await this.stopTrackingProgress();
    },
    async stopTrackingProgress() {
      await this.trackProgress();
      clearTimeout(this.playProgressInterval);
    },
    async trackProgress() {
      if (
          this.player === undefined ||
          this.player === null ||
          !this.player.duration
      ) {
        return;
      }
      let el = this.$refs.progressBar;
      let holder = _.get(this, '$refs.progressHolder', null)
      if (el === null || holder === null || this.player === null) {
        return;
      }

      let holderOffsetWidth = _.get(holder, 'offsetWidth', 0)
      let decimalTime = Math.round(this.player.currentTime * 100) / 100;
      let p = decimalTime / this.player.duration;
      this.progressBarWidth = p * holderOffsetWidth;
      this.setTooltipXPosition(p);

      if (this.player.currentTime === this.player.duration) {
        await this.reset();
      }

      window.shared = {
        curTime: decimalTime,
      };

      if (this.lastTimeUpdateSecond !== decimalTime) {
        this.lastTimeUpdateSecond = decimalTime;
        this.$root.$emit("player:progress:change", decimalTime);
      }

      this.playProgressInterval = setTimeout(async () => await this.trackProgress(), 10);
    },
    getIndicatorStyle(annotation) {
      let holder = _.get(this, '$refs.progressHolder', null)
      if (this.player === null || holder === null) {
        return {};
      }
      let holderOffsetWidth = _.get(holder, 'offsetWidth', 0)
      let p = annotation.curTime / this.player.duration;
      let totalWidth = holderOffsetWidth;
      let left = Math.min(holderOffsetWidth - 6, totalWidth * p)
      return {
        left: left + "px",
      };
    },
    setPlayProgress(curPageX, leftMin, totalWidth) {
      let newPercent = (curPageX - leftMin) / totalWidth;
      if (newPercent < 0) {
        newPercent = 0;
      } else if (newPercent > 1) {
        newPercent = 1;
      }
      this.goTo(newPercent * this.player.duration);
    },
    async handleMouseDownOnAudioWaveForm(e) {
      if (!isNaN(e)) {
        await this.play().catch(err => console.warn(err));
        await this.pause();
        return this.goTo(e * this.player.duration);
      }

      clearTimeout(this.playProgressInterval);
      await this.play().catch(err => console.warn(err));
      await this.pause();

      let self = this;
      if (!this.$refs.audioWaveHolder) return;
      let boundingRect = this.$refs.audioWaveHolder.getBoundingClientRect();
      let leftMin = boundingRect.left;
      let totalWidth = boundingRect.width;

      const onMove = (moveEvent) => {
        if (!self.movingProgressBar) return;
        if (moveEvent.touches && moveEvent.touches.length > 0) {
          self.setPlayProgress(moveEvent.touches[0].clientX, leftMin, totalWidth);
        } else {
          self.setPlayProgress(moveEvent.pageX, leftMin, totalWidth);
        }
      };

      const onEnd = () => {
        document.removeEventListener('touchmove', onMove);
        document.removeEventListener('touchend', onEnd);
        document.removeEventListener('mousemove', onMove);
        document.removeEventListener('mouseup', onEnd);
        self.movingProgressBar = false;
      };

      document.addEventListener('touchmove', onMove);
      document.addEventListener('touchend', onEnd);

      document.addEventListener('mousemove', onMove);
      document.addEventListener('mouseup', onEnd);

      this.movingProgressBar = true;
      onMove(e);
    },
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    },
    toggleVideoFullScreen: function () {
      const videoElement = this.$refs.videoHtml;

      if (videoElement) {
        try {
          if (videoElement.requestFullscreen) {
            videoElement.requestFullscreen();
          } else if (videoElement.mozRequestFullScreen) {
            videoElement.mozRequestFullScreen();
          } else if (videoElement.webkitRequestFullscreen) {
            videoElement.webkitRequestFullscreen();
          } else if (videoElement.msRequestFullscreen) {
            videoElement.msRequestFullscreen();
          } else if (videoElement.webkitEnterFullscreen) {
            videoElement.webkitEnterFullscreen();
          } else {
            console.error('Fullscreen not supported');
          }
        } catch (error) {
          console.error('Error toggling fullscreen:', error);
        }
      } else {
        console.error('Video element not found');
      }
    },
    closeFullscreen: function() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) { // For Safari
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) { // For Firefox
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) { // For IE11
        document.msExitFullscreen();
      }
    },
    isFullscreenMode(videoElement) {
      return document.fullscreenElement === videoElement || 
             document.webkitFullscreenElement === videoElement || 
             document.mozFullScreenElement === videoElement || 
             document.msFullscreenElement === videoElement;
    },
    async pauseAndExitFullScreen(e) {
      e.preventDefault();
      await this.pause();
      const videoElement = this.$refs.videoHtml;
      this.isFullscreenMode(videoElement) && this.closeFullscreen();
    },
    async handleStartOnProgressBar(event) {
      const progressHolder = this.$refs.progressHolder;
      let self = this;

      clearTimeout(this.playProgressInterval);
      await this.play().catch(err => console.warn(err));
      await this.pause();

      const onMove = (moveEvent) => {
        if (!self.movingProgressBar) return;
        const rect = progressHolder.getBoundingClientRect();
        if (moveEvent.touches && moveEvent.touches.length > 0) {
          self.setPlayProgress(moveEvent.touches[0].clientX, rect.left, rect.width);
        } else {
          self.setPlayProgress(moveEvent.pageX, rect.left, rect.width);
        }
      };

      const onEnd = () => {
        document.removeEventListener('touchmove', onMove);
        document.removeEventListener('touchend', onEnd);
        document.removeEventListener('mousemove', onMove);
        document.removeEventListener('mouseup', onEnd);
        self.movingProgressBar = false;
      };

      document.addEventListener('touchmove', onMove);
      document.addEventListener('touchend', onEnd);

      document.addEventListener('mousemove', onMove);
      document.addEventListener('mouseup', onEnd);

      this.movingProgressBar = true;
      onMove(event);
    },
  },
};

</script>
<template>
  <div>
    <video
        ref="videoHtml"
        v-if="isVideo"
        v-on:mousedown="$emit('mousedown', $event)"
        v-on:loadedmetadata="onMetaDataLoaded"
        v-on:canplay="onPlayerLoadStart"
        v-on:loadstart="onPlayerLoadStart"
        v-on:loadeddata="onPlayerLoaded"
        v-on:play="onPlayerPlay"
        v-on:pause="onPlayerPause"
        v-on:ended="reset"
        :style="{ width: curWidth + 'px', height: zoomMode === 0 && !isMobile ? curHeight - 120 + 'px' : '100%'}"
        class="video-player"
        :class="{ 'bg-dark': isMobile && isVideo }"
        preload="auto"
        playsinline
        metadata="preload"
        controlslist="nodownload"
        disablePictureInPicture="true"
        muted
        @click="pauseAndExitFullScreen"
    >
      <source :src="src" :type="contentType"/>
      Your browser does not support HTML5 video.
    </video>
    <div
        v-if="docType === 'audio'"
        v-on:mousedown="handleMouseDownOnAudioWaveForm"
        v-on:touchstart.prevent="handleMouseDownOnAudioWaveForm"
        ref="audioWaveHolder"
        class="mt-5"
    >
      <wavesurfer ref="waveform"
                  :media-component="`audioHtml_${fileId}`"
                  :click="handleMouseDownOnAudioWaveForm"
                  :annotations="annotations"
      ></wavesurfer>
      <audio
          :id="`audioHtml_${fileId}`"
          ref="audioHtml"
          :src="src"
          :type="contentType"
          v-if="isAudio"
          v-on:canplay="onPlayerLoadStart"
          v-on:loadstart="onPlayerLoadStart"
          v-on:loadedmetadata="onMetaDataLoaded"
          v-on:loadeddata="onPlayerLoaded"
          v-on:play="onPlayerPlay"
          v-on:pause="onPlayerPause"
          v-on:ended="reset"
          metadata="preload"
          controlslist="nodownload"
          style="width: 100%; height: auto; display: none"
          preload="auto"
          playsinline
          muted
      ></audio>
    </div>
    <!--  Add Comment -->
    <div v-if="!isRecording" class="tooltip b-tooltip bs-tooltip-bottom audio-comment-button"
         :style="{ left: tooltipXPosition + '%', top: '170px' }"
         @click="emitNewCommentEvent()"
         v-show="
          docType === 'audio' &&
          state === 0 &&
          this.player.currentTime !== 0 &&
          this.player.currentTime < this.player.duration">
      <div class="arrow" style="left: 41px"></div>
      <div class="tooltip-inner">
        <div class="font-weight-normal">{{ getLabel('review_screen_labels', 'audio_add_comment') }}</div>
      </div>
    </div>
    <!--  control bar  -->
    <div class="video-controls h-50 mt-3"
         :class="{'px-lg-1' : isAudio, 'px-lg-5' : isVideo}"
         :style="{width: isVideo ? curWidth + 'px' : '100%'}"
         style="border-radius: 6px !important;">
      <div>
        <b-row style="height: 82px !important; border-radius: 6px">
          <!-- progress bar -->
          <b-col :class="isMobile ? 'col-12 mt-3' : ' col-lg-12 d-flex pr-0 pt-2 pl-1 align-items-center'">
            <div
                class="progress-container"
                v-on:mousedown="handleStartOnProgressBar"
                v-on:touchstart.prevent="handleStartOnProgressBar"
                ref="progressHolder"
                id="progress-bar-holder"
            >
              <div id="progress-bar" ref="progressBar" :style="{ width: progressBarWidth + 'px' }" style="height: 15px"></div>
              <div
                  v-for="a in annotations"
                  class="comment-indicator"
                  :id="'comment_indicator_' + a.id"
                  @click="goTo(a.curTime)"
                  :style="getIndicatorStyle(a)"
              ></div>
            </div>
          </b-col>


          <!-- control buttons -->
          <b-col class="justify-content-start justify-content-start d-flex align-items-center" :class="isMobile ? 'col-6': 'col-lg-3 pl-3 pr-0 mr-0'">
            <div class="video-control-buttons d-flex">
              <div v-show="state === 0" @click="play()">
                <img src="/img/play-icon.svg" style="height: 30px; width: 30px"/>
              </div>
              <div v-show="state === 1" @click="pause()">
                <img src="/img/pause-icon.svg" style="height: 30px; width: 30px"/>
              </div>
            </div>
            <!--  current time -->
            <div class="pl-3">
              {{ getTimeFormatFromSeconds($data.lastTimeUpdateSecond) }} / {{
                getTimeFormatFromSeconds(Math.round((this.player && this.player.duration ? this.player.duration : 0) * 100) / 100)
              }}
            </div>
          </b-col>
          <!--  volume -->
          <b-col class="justify-content-end d-flex align-items-center" :class="isMobile ? 'col-4 ml-auto mr-2': ' col-lg-9 pr-2'">
            <div class="volume-mute mr-3" @click="toggleMute()">
              <img src="/img/volume-icon.svg" v-show="!playerMuted" style="height: 30px; width: 30px"/>
              <img src="/img/mute-icon.svg" v-show="playerMuted" style="height: 30px; width: 30px"/>
            </div>
            <div v-show="!isMobile" class="pl-0 mr-0">
              <vue-slider
                  class="volume-slider"
                  :style="docType === 'audio' ? 'width: 145px !important;': 'width: 125px !important;'"
                  v-model="volumeValue"
              />
            </div>
            <div v-show="docType === 'video'">
              <img src="/img/arrows-expand-icon.svg" @click="toggleVideoFullScreen" class="expand-icon"/>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<style scoped>
.audio-comment-button .tooltip-inner:active {
  box-shadow: 0 3px 20px 0 rgba(84, 110, 122, 0.1);
  transform: translateY(-1px);
  z-index: 9999;
}

.tooltip-inner {
  background-color: #212B36;
  line-height: 18px;
  font-size: 14px;
}


.audio-comment-button:hover,
.volume-mute:hover,
.volume-slider:hover,
.expand-icon:hover,
.video-control-buttons:hover {
  cursor: pointer;
}

.video-player:fullscreen {
  cursor: default !important;
  background-color: #2d2d2d;
}

.audio-comment-button {
  user-select: none;
  display: block;
  opacity: 0.9;
  position: absolute;
  top: 132px;
  padding: 0.4rem 0;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  line-break: auto;
  z-index: 1070;
  cursor: default;
}

.comment-indicator {
  position: absolute;
  z-index: 2;
  top: 0px;
  height: 15px;
  width: 15px;
  background: #FAD546;
  border-radius: 24px;
}

.waveform:first-child {
  margin-top: 35px;
  margin-left: 16px;
  margin-right: 17px;
}

.video-controls {
  background: white;
  position: relative;
  margin-bottom: 8px;
  cursor: default;
}

.video-icon {
  width: 28px;
  height: 28px;
  color: rgba(0, 0, 0, .4) !important;
  margin-left: 10px;
}

.time-stamp {
  color: #252526;
  font-size: 15px;
  font-weight: 400;
  font-family: SF Pro;
}

.expand-icon {
  height: 28px;
  width: 28px;
  cursor: pointer;
}

.audio-icon {
  width: 28px;
  height: 28px;
  color: rgba(0, 0, 0, .4) !important;
}

.audio-icon:hover, .video-icon:hover {
  cursor: pointer;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #212B36 !important;
}

.volume-slider {
  height: 10px !important;
  border-radius: 3px !important;
  right: 10px !important;
  margin-top: 3px;
}

.progress-container {
  position: absolute;
  height: 15px;
  left: 17px;
  right: 17px;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  border-radius: 24px;
  background: var(--gray-100, #EBEBED);
}

#progress-bar {
  position: absolute;
  height: 20px;
  left: 0px;
  right: 0px;
  top: 0px;
  width: 0%;
  font-size: 12px;
  line-height: 20px;
  border-radius: 24px;
  text-align: center;
  background-color: #252526 !important;
}


</style>
