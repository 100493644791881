<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue';

export default {
  name: "proof-view-lang",
  components: {
    BDropdown,
    BDropdownItem
  },
  props: ["isMobile", "curDisplayLang"],
}
</script>

<template>
  <b-nav :class="isMobile ? 'col-2 pl-0':'d-flex align-items-center gap-2 pl-2 order-3 order-lg-4'">
    <a class="d-none d-lg-flex align-items-center justify-content-center flex-shrink-0 py-3 px-2 w-38 action-btn font-weight-bold text-gray-900 text-decoration-none text-bold"
       style="cursor: pointer; font-size: 17px"
       @click="$emit('start-tour')">
      ?
    </a>
    <b-dropdown>
      <template #button-content>
        <div class="d-flex align-items-center justify-content-center flex-shrink-0 p-0 w-38 action-btn fw-medium text-gray-900 text-decoration-none bg-transparent" style="font-size: 17px">
          {{ curDisplayLang.toUpperCase() }}
        </div>
      </template>
        <b-dropdown-item @click="$emit('on-set-lang', 'en-us')"
                         class="text-decoration-none align-items-center text-gray-700 py-2 px-3">
          <span>English</span>
        </b-dropdown-item>
        <b-dropdown-item @click="$emit('on-set-lang', 'es-us')"
                         class="text-decoration-none align-items-center text-gray-700 py-2 px-3">
          <span>Spanish</span>
        </b-dropdown-item>
    </b-dropdown>
  </b-nav>
</template>

<style scoped>
</style>
