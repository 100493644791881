<template>
  <div class="toolbar d-flex py-2 py-lg-0">
    <div class="toolbar-item d-flex align-items-center justify-content-center"
      :class="{'gap-3' : isCompare}">
      <a class="d-block action-btn p-2"
         style="cursor: pointer"
         @mousedown="mouseDown(zoomRate)"
         @mouseup="mouseUp"
         @dragstart="mouseUp"
      >
        <img src="/img/review/icons/zoom-in.svg" class="d-block" width="20" height="20" alt="">
      </a>
      <a @click="toggleZoomMode" x-data="{zoomWidth : true}" class="action-btn p-2" style="cursor: pointer">
        <img x-cloak :class="zoomMode === 0 ? 'd-block' : 'd-none' " x-show="zoomWidth"
             src="/img/review/icons/zoom-Icon.svg" width="25" height="20" alt="">
        <img x-cloak :class="zoomMode === 1 ? 'd-block' : 'd-none' " src="/img/review/icons/zoom-height.svg" width="25"
             height="20" alt="">
      </a>
      <a class="d-block action-btn p-2"
         style="cursor: pointer"
         @mousedown="mouseDown(-1 * zoomRate)"
         @mouseup="mouseUp"
         @dragstart="mouseUp"
      >
        <img src="/img/review/icons/zoom-out.svg" class="d-block" width="20" height="20" alt="">
      </a>
      <a v-if="showRotate" class="d-block action-btn p-2" @click="rotate()" style="cursor: pointer">
        <img src="/img/review/icons/rotate-right.svg" class="d-block" width="20" height="20" alt="">
      </a>
      <a class="d-block action-btn p-2" v-if="isDragableFile && isThirdWheelZoomActive" @click="toggleDragMode()" style="cursor: pointer" :class="{'drag-mode-selected': isDragEnabled}">
        <img src="/img/review/icons/drag-icon.svg" class="d-block" width="20" height="20" alt="drag-icon">
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "proof-view-zoom-tools",
  props : ['updateZoom', 'curZoom', 'zoomMode', 'rotate', 'toggleZoomMode', 'isCompare', 'curFile', 'showRotate', 'toggleDragMode', 'isDragEnabled', 'isDragableFile', 'isThirdWheelZoomActive'],
  data : () => ({
    isMouseDown : false,
    zoomIncrement : 0,
    zoomRate: 0.025,
    zoomInterval: 50
  }),
  methods : {
    mouseDown(zoomIncrement){
      this.isMouseDown = true;
      this.zoomIncrement = zoomIncrement;
      this.updateZoom(this.curZoom + this.zoomIncrement);
      setTimeout(this.whileMouseDown, 200);
    },
    whileMouseDown() {
      if (!this.isMouseDown) return;
      this.updateZoom(this.curZoom + this.zoomIncrement);
      setTimeout(this.whileMouseDown, this.zoomInterval);
    },
    mouseUp() {
      this.isMouseDown = false;
    }
  },
  computed : {
  }
}
</script>

<style scoped>

[x-cloak]{
  display: none;
}

.drag-mode-selected {
  background-color: rgb(209 213 219);
  border-radius: 4px;
}
</style>
