<script>
import ReviewPageV1 from './ReviewPage.v1.vue';
import ReviewPageV2 from './ReviewPage.v2.vue';

export default {
  components: {
    ReviewPageV1,
    ReviewPageV2
  },
  data() {
    return {
      publicId: this.$route.path.split("/")[2],
      approverPublicId: this.$route.path.split("/")[3],
      curAccount: null,
    };
  },
  async created() {
    this.curAccount = await this.$A.ReviewService.GetCurrentProofAccountOwner(this.publicId);
  },
  computed: {
    version: function () {
      if (!this.curAccount) return -1;
      const screenTheme = _.get(this.curAccount, "themeSettings.reviewScreenTheme", 0);
      return `${screenTheme}` === '10' ? 2 : 1;
    },
  },
  watch: {},
};
</script>

<template>
  <div>
    <review-page-v1
        v-if="version===1"
        :public-id="publicId"
        :approver-public-id="approverPublicId"
        :cur-account="curAccount"
    />
    <review-page-v2
        v-if="version===2"
        :public-id="publicId"
        :approver-public-id="approverPublicId"
        :cur-account="curAccount"
    />
  </div>
</template>