<script>
export default {
  name: "ComparePage"
}
</script>

<template>
  <div>
    <h1>
      New Compare Page
    </h1>
  </div>

</template>

<style scoped>

</style>