import { render, staticRenderFns } from "./audioAndVideoReview.vue?vue&type=template&id=1a35ef13&scoped=true&"
import script from "./audioAndVideoReview.vue?vue&type=script&lang=js&"
export * from "./audioAndVideoReview.vue?vue&type=script&lang=js&"
import style0 from "./audioAndVideoReview.vue?vue&type=style&index=0&id=1a35ef13&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a35ef13",
  null
  
)

export default component.exports