<script>
export default {
  name: "proof-view-free-user-promo",
  props: ["avatar","curAccount", "proofViewVersion"],
  methods: {
    subscribe : function() {
      return window.open(`https://ashoreapp.com?referrer=${this.curAccount.id}`,'_blank');
    }
  }
}
</script>

<template>
<b-card :class="proofViewVersion !== 'minimal' ? 'm-3 rounded p-3': 'rounded p-3 mx-0 mb-2'" style="background: white; border: 1px solid rgba(213, 213, 216, 1)">
  <div class="row d-flex align-items-start justify-content-around flex-wrap" >
    <div class="col-2 d-flex align-items-start">
      <b-img :src="avatar" style="height: 40px"></b-img>
    </div>
    <div class="col-10 text-wrap" style="cursor: pointer" @click="subscribe">
      <div class="header-text">Love Reviewing with Ashore?</div>
      <div class="pt-2 sub-text ml-auto">Join thousands of creatives already getting better feedback on files faster.</div>
      <div class="d-flex justify-content-between mt-2 pb-0" style="font-weight: 400"><span>Get Started for Free</span><img src="/img/review/icons/arrow-right.svg"></div>
    </div>
  </div>
</b-card>
</template>

<style scoped>

/deep/ .card-body {
  padding: 0px !important;
}

.sub-text {
  font-family: "Open Sans";
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: rgba(112, 112, 115, 1);
}

.header-text {
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 14px;
  color: rgba(23, 45, 25, 1);
}

free-text {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 14px;
  color: rgba(37, 37, 38, 1);
}

</style>