<script>
export default {
  name: "proof-view-pagination",
  props: ["numPages", "curPage", "GoToNextPage", "GoToPage", "GoToPreviousPage", "isCompare"],
  computed : {
    onFirstPage() {
      return `${this.curPage}`==='1';
    },
    onLastPage() {
      return `${this.curPage}`===`${this.numPages}`;
    }
  },
  methods : {
    handleInput: function(event) {
      this.GoToPage(event.target.value);
    }
  },
  watch: {
    curPage: function(newVal) {
      this.pageInput = newVal;
    }
  }
}
</script>

<template>
  <div class="toolbar pagination-toolbar d-flex align-items-center  gap-3 fs-14 ml-lg-auto  py-3 py-lg-0"
       :class="{'gap-3' : isCompare}">
    <a @click.prevent="GoToPreviousPage" :class="{'opacity-50' : onFirstPage}" class="text-decoration-none text-gray-700 d-flex align-items-center gap-2" href="">
      <img src="/img/review/icons/chevron-left.svg" width="20" alt="">
      <span>Previous</span>
    </a>
    <div class="text-gray-700 d-flex align-items-center gap-1">
      <span>Page</span>
      <input type="text" class="px-2 border border-gray-300 d-block rounded-2 text-ashore-black"
             style="width: 40px;" :value="curPage" @input="handleInput">
      <span>of {{numPages}}</span>
    </div>
    <a @click.prevent="GoToNextPage" :class="{'opacity-50' : onLastPage}" class="text-decoration-none text-gray-700 d-flex align-items-center gap-2" href="">
      <span>Next</span>
      <img src="/img/review/icons/chevron-right.svg" width="20" alt="">
    </a>
  </div>
</template>

<style scoped>
@media(max-width:680px){
  .pagination-toolbar{
    right: auto !important;
    left: 0;
    top: auto !important;
    bottom: 0;
  }
}
</style>