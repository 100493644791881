<template>
  <div class="toolbar py-2 p-lg-0 px-3">
    <div class="ashore-toggle">
      <label class="switch">
        <input type="checkbox" :checked="liveProofCommentsEnabled" @input="toggleLiveProofCommentsEnabled" :disabled="isRecording"/>
        <div class="slider round"></div>
      </label>
      <span>{{ getLabel('review_screen_labels', 'web_comment_mode_toggle') }}
        <img id="live-toggle-comments-icon" src="/img/icon-help.svg" style="height: 20px; cursor: pointer"/>
        <b-tooltip target="live-toggle-comments-icon" triggers="hover">
          {{ getLabel('review_screen_labels', 'web_comment_mode_toggle_info') }}
        </b-tooltip>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "proof-view-live-proof-comments",
  props : ["liveProofCommentsEnabled", "isRecording"],
  data : function () {
    return {
      sizeDropdown: false,
    }
  },
  methods: {
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    },
    toggleLiveProofCommentsEnabled() {
      this.$emit('toggle-live-proof-comments');
    }
  }
}
</script>

<style scoped>

</style>