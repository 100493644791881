<script>
export default {
  components: {},
  props: {
  },
  data() {
    return {
      id: this.$A.GetUUID(),
    };
  },
  updated: function () {},
  watch: {},
  mounted() {
    let self = this;
  },
  computed: {},
  methods: {
    open: function () {
      this.$bvModal.show(this.id);
    },
    close: function (){
      this.$bvModal.hide(this.id);
    }
  },
};
</script>
<template>
  <div id="navigation-before-decision-modal">
    <b-modal :id="id" size="lg">
      <template v-slot:modal-header>
        <div class="w-100 text-center" style="margin-top:30px;">
          <h4></h4>
          <p>
            Please make a decision on the proof before exiting this page.
          </p>
        </div>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <div class="w-100">
          <b-button
              class="button button-green button-light normal-button"
              style="max-width: 200px; display: block; margin: 0 auto;"
              @click="close()"
          >OK</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<style scoped></style>
