<script>
import WaveSurfer from "wavesurfer.js/dist/wavesurfer.esm";
import RegionsPlugin from "wavesurfer.js/dist/plugins/regions";

export default {
  name: 'wavesurfer',
  components: {
    WaveSurfer,
  },
  props: {
    mediaComponent: {
      require: true,
      type: String
    },
    barWidth: {
      type: Number,
      default: 5
    },
    barGap: {
      type: Number,
      default: 2
    },
    barRadius: {
      type: Number,
      default: 5
    },
    waveColor: {
      type: String,
      default: '#B5B5B8'
    },
    progressColor: {
      type: String,
      default: '#448BE2'
    },
    annotations: {
      type: Array
    },
    click: {
      type: Function
    }
  },
  data: () => ({
    wavesurfer: null,
    regions: null,
    length: 1
  }),
  async mounted() {
    this.createWaveSurfer();
  },
  methods: {
    createWaveSurfer() {
      let self = this;
      this.regions = RegionsPlugin.create();
      this.wavesurfer = WaveSurfer.create({
        container: this.$refs.waveform,
        // normalize: true,
        barWidth: this.barWidth,
        waveColor: this.waveColor,
        progressColor: this.progressColor,
        barGap: this.barGap,
        barRadius: this.barRadius,
        backend: 'MediaElement',
        cursorWidth: 0,
        media: document.getElementById(this.mediaComponent),
        plugins: [this.regions]
      });
      this.wavesurfer.on('click', this.click);
      this.wavesurfer.on('decode', (length) => {
        self.length = length;
        self.loadAnnotations(self.annotations);
      });
    },
    loadAnnotations(annotations) {
      annotations = annotations || this.annotations;
      let self = this;
      const width = this.length/100;
      this.regions.clearRegions();
      annotations.forEach(a => {
        self.regions.addRegion({
          start: a.curTime,
          end: a.curTime + width,
          drag: false,
          resize: false,
          color: 'rgba(250, 213, 70, 0.5)'
        });
      });
    }
  },
  watch: {
    annotations: function (newAnnotations) {
      this.loadAnnotations(newAnnotations);
    }
  }
}
</script>
<template>
  <div ref="waveform"></div>
</template>
