<script>
import redactor from "../../form/redactor.vue";

export default {
  name: "approverResponseModal",
  components: {
    redactor,
  },
  props: {
    proof: {
      required: true,
    },
    account: {
      required: true,
    },
    commentApiUrl: {
      required: true,
    },
  },
  methods: {
    open: function (mode, approverFeedback) {
      if (this.feedBackReceived !== null || approverFeedback !== null) {
        return;
      }
      this.mode = mode;
      this.visible = true;
    },
    saveProgressAndCancel: function () {
      this.feedbackText = this.$refs.redactorFeedback.getTextBody();
      this.visible = false;
    },
    saveResponse() {
      let self = this;
      if (!this.checkListStatus) {
        this.errorMessage =
            this.getLabel('approver_decision_modal_labels', 'approver_error_msg');
        return;
      }
      this.feedBackText = this.$refs.redactorFeedback.getTextBody();
      let fileApprovalStatus = [];
      this.files.forEach(function (f) {

        if (self.allowApproveIndividualFiles) {
          fileApprovalStatus.push({
            fileId: f.id,
            fileName: f.name,
            approved: f.approvalFeedback == "1",
            approvedWithChanges: f.approvalFeedback == "2",
          });
        } else {
          fileApprovalStatus.push({
            fileId: f.id,
            fileName: f.name,
            approved: self.mode == "1",
            approvedWithChanges: self.mode == "2",
          });
        }
      });

      this.$emit("approverResponded", {
        approvalChoice: this.mode,
        feedBackText: this.feedBackText,
        filesApprovalStatus: fileApprovalStatus,
        // we only want to save the checklist if not rejecting all files
        confirmedChecklistItems: this.mode !== 0 ? self.proofRequirements.map((r) => r.name) : [],
      });
      this.feedBackReceived = true;
      this.visible = false;
    },
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    }
  },
  computed: {
    allowApproveIndividualFiles: function () {
      return this.proof !== null &&
      this.proof.allowApproveIndividualFiles !== undefined
          ? this.proof.allowApproveIndividualFiles
          : false;
    },
    allowApproveWithChanges: function () {
      return this.proof !== null &&
      this.proof.allowApproveWithChanges !== undefined
          ? this.proof.allowApproveWithChanges
          : false;
    },
    files: function () {
      let files = _.get(this, 'proof.currentVersion.versionFiles', [])
      for (let i = 0; i < files.length; i++) {
        files[i].approvalFeedback = 1;
      }
      return files;
    },
    proofRequirements: function () {
      return this.account !== null ? this.account.proofRequirements : [];
    },
    checkListStatus: function () {
      if (this.mode !== 0 && this.proofRequirements.length > 0) {
        let allChecked = true;
        this.proofRequirements.forEach(function (r) {
          if (r.acknowledge === undefined || r.acknowledge === false) {
            allChecked = false;
          }
        });
        return allChecked;
      }
      return true;
    },
  },
  data() {
    return {
      id: this.$A.GetUUID(),
      mode: -1,
      feedBackText: "",
      errorMessage: "",
      feedBackReceived: null,
      visible: false,
    };
  },
};
</script>

<template>
  <!-- Add Note Modal -->
  <b-modal v-model="visible" :id="id" size="lg">
    <div class="w-100 text-center">
      <h3 v-if="mode === 0">{{ getLabel('approver_decision_modal_labels', 'reject_modal') }}</h3>
      <h3 v-if="mode === 1">{{ getLabel('approver_decision_modal_labels', 'approve_modal') }}</h3>
      <h3 v-if="mode === 2">{{ getLabel('approver_decision_modal_labels', 'approve_with_changes') }}</h3>
      <h3 v-if="mode === 3">{{ getLabel('approver_decision_modal_labels', 'submit_decision_header') }}</h3>
    </div>

    <!-- Individual File Approval -->
    <div
        class="w-100 text-center"
        style="margin-top: 50px"
        v-if="allowApproveIndividualFiles && mode === 3"
    >
      <p class="mt-25">{{ getLabel('approver_decision_modal_labels', 'approve_individual_file') }}</p>
    </div>

    <div
        class="row"
        v-if="proof !== null && allowApproveIndividualFiles && mode === 3"
    >
      <div class="col-md-12 m-auto text-center">
        <div
            class="row review-toggle"
            v-for="file in files"
            v-bind:key="file.id"
        >
          <div class="col-12 col-lg-12">
            {{ file.name }} is
            <select
                class="file-approval-dropdown"
                v-model="file.approvalFeedback"
            >
              <option value="1" style="color: green">{{ getLabel('review_screens_labels', 'approve_btn') }}</option>
              <option
                  value="2"
                  style="color: green"
                  v-if="allowApproveWithChanges"
              >
                {{ getLabel('review_screens_labels', 'approve_with_changes_btn') }}
              </option>
              <option value="3" style="color: red">{{ getLabel('review_screens_labels', 'not_approved_btn') }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <!-- Approval Checklist -->
    <div
        class="w-100 text-center"
        style="margin-top: 50px"
        v-if="mode !== 0 && proofRequirements.length > 0"
    >
      <p class="mt-25">{{ getLabel('approver_decision_modal_labels', 'approver_acknowledge_checklist') }}</p>
    </div>

    <div class="row">
      <div class="col-md-6 m-auto mobile-wrap-display">
        <div
            class="row ashore-toggle mobile-view"
            v-if="mode !== 0"
            v-for="item in proofRequirements"
            v-bind:key="item.id"
        >
          <div class="col-3 col-lg-2">
            <label class="switch">
              <input type="checkbox" v-model="item.acknowledge"/>
              <div class="slider round"></div>
            </label>
          </div>
          <div class="col-9 col-lg-10" v-html="item.name"></div>
        </div>
      </div>
    </div>

    <!-- General Feedback -->
    <div class="row" style="margin-top: 25px">
      <div class="col-md-12 m-auto">
        <span class="text-center" style="display: block; margin: 25px 0 15px"
        >{{ getLabel('approver_decision_modal_labels', 'feedback_box') }}</span
        >
        <redactor
            ref="redactorFeedback"
            :text="feedBackText"
            :commentApiUrl="commentApiUrl"
        />
      </div>
    </div>

    <div class="w-100 text-center" style="margin-top: 50px">
      <strong class="mt-25" style="color: red">{{ errorMessage }}</strong>
    </div>
    <template v-slot:modal-footer="{ cancel }">
      <div class="w-100 d-flex justify-content-end align-items-center">
        <b-button
            class="gray-btn"
            @click="saveProgressAndCancel()"
        >{{ getLabel('approver_decision_modal_labels', 'cancel_modal_btn') }}
        </b-button
        >
        <b-button
            class="green-btn button"
            v-if="mode === 1"
            :style="{ opacity: checkListStatus ? 1 : 0.5 }"
            @click="saveResponse"
        >{{ getLabel('approver_decision_modal_labels', 'approve_btn') }}
        </b-button
        >
        <b-button
            class="ml-1 green-btn"
            v-if="mode === 2"
            :style="{ opacity: checkListStatus ? 1 : 0.5 }"
            @click="saveResponse"
        >{{ getLabel('approver_decision_modal_labels', 'approve_with_changes_btn') }}
        </b-button
        >
        <b-button
            class="red-btn"
            v-if="mode === 0"
            @click="saveResponse"
        >{{ getLabel('approver_decision_modal_labels', 'not_approved_btn') }}
        </b-button
        >
        <b-button
            class="green-btn"
            v-if="mode === 3"
            :style="{ opacity: checkListStatus ? 1 : 0.5 }"
            @click="saveResponse"
        >{{ getLabel('approver_decision_modal_labels', 'submit_decision_btn') }}
        </b-button
        >
      </div>
    </template>
  </b-modal>
</template>

<style scoped>
.review-toggle {
  font-weight: bold;
  padding-bottom: 10px;
}

.review-toggle .switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
  margin-right: 10px;
}

.review-toggle .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.review-toggle .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eef2f5;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.file-approval-dropdown {
  border: none;
  background-color: transparent;
  border-bottom: 1px solid;
  border-radius: 0;
  min-width: 200px;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 2px 0;
  background-image: url(/img/caret-dark.svg);
  background-size: 15px;
  background-position: right;
  background-repeat: no-repeat;
  margin-left: 5px;
}

.review-toggle .slider:before {
  background-color: #d44d68;
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.review-toggle input:checked .slider {
  background-color: #ffabbc;
}

.review-toggle input:focus + .slider {
  box-shadow: 0 0 1px #dee3ec;
}

.review-toggle input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.review-toggle .slider.round {
  border-radius: 34px;
}

.review-toggle .slider.round:before {
  border-radius: 50%;
}

.gray-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #444444 !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  filter: drop-shadow(0px 1px 2px rgba(8, 11, 13, 0.05));
  border-radius: 6px;
  padding: 10px !important;
}

.gray-dark-btn {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: black !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  border-radius: 6px;
  padding: 10px !important;
  background: darkgray;
}

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px !important;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgba(8, 11, 13, 0.5);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  color: #fff !important;
}

.green-btn:hover {
  background-color: #019255 !important;
  opacity: 0.9 !important;
}

.gray-btn:hover {
  background-color: lightgray;
  border: lightgray solid 1px;
}

.red-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 9px 17px;
  height: 36px;
  background: #D44D68;
  border-radius: 6px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  padding: 9px 17px !important;
  margin-left: 10px;
  color: #fff !important;
}

.red-btn:hover {
  background: #D44D68;
  opacity: 0.9;
}

@media screen and (max-width: 767px) {
  .mobile-view {
    width: 50% !important;
  }

  .mobile-wrap-display {
    align-items: center !important;
    display: flex !important;
    flex-direction: column !important;
  }
}

@media screen and (max-width: 450px) {

  .mobile-view {
    width: 70% !important;
  }

}

</style>
