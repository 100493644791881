<template>
  <div x-data="{selectValue: 'Single Click to Add Comment'}" class="dropdown toolbar py-2 p-lg-0 px-3">
    <button @click="commentDropdown= !commentDropdown"
            data-toggle="dropdown"
            class="btn btn-toolbar w-100 text-gray-700 fw-normal border border-gray-300 d-flex align-items-center bg-ashore-white px-3 py-2 gap-2">
      <div class="icon">
        <img :src="curAnnotationType.image" width="16" alt="">
      </div>
      <span class="font-weight-normal" x-text="selectValue">{{ curAnnotationType.title() }}</span>
      <img src="/img/review/icons/chevron-down.svg" class="ml-auto" width="20" alt="">
    </button>
    <div x-ref="commentsDraw"
         class="dropdown-menu w-100 bg-ashore-white box-shadow3xl rounded-3 py-1 border-0 position-absolute top-full mt-1">
      <ul class="list-unstyled mb-0 fs-14 text-center">
        <li v-for="annoType in annotationTypes">
          <a @click="setCurAnnotationType(annoType)"
             class="d-flex align-items-center gap-2 dropdown-item text-decoration-none text-gray-700 py-3 px-3">
            <div class="icon">
              <img :src="annoType.image" width="16" alt="">
            </div>
            <span>{{ annoType.title() }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "proof-view-new-annotations",
  props: ["curFile", "curAnnotationType", "isMobile", "setCurAnnotationType"],
  data: function () {
    return {
      commentDropdown: false,
    }
  },
  methods: {
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    }
  },
  computed: {
    annotationTypes: function () {
      if (this.curFile) {
        let annotationTypes = [
          {
            docTypes: ["document", "video", "image", "html", "web_url"],
            type: "point",
            title: () => this.getLabel('review_screen_labels', 'anno_single_click'),
            image: "/img/icon-doubleclick.svg",
            cursor: "/img/icon-doubleclick.png",
          },
          {
            docTypes: ["document", "video", "image", "html", "web_url"],
            type: "rect",
            title: () => this.getLabel('review_screen_labels', 'anno_rect'),
            image: "/img/icon-rectangle.svg",
            cursor: "/img/icon-rectangle.png",
          },
          {
            docTypes: ["document", "video", "image", "html", "web_url"],
            type: "arrow",
            title: () => this.getLabel('review_screen_labels', 'anno_arrow'),
            image: "/img/icon-arrow.svg",
            cursor: "/img/icon-arrow.png",
          },
          {
            docTypes: ["document", "video", "image", "html", "web_url"],
            type: "draw",
            title: () => this.getLabel('review_screen_labels', 'anno_draw'),
            image: "/img/draw.svg",
            cursor: ""
          },
        ];

        if (this.isMobile) {
          return annotationTypes.filter((i) => (i.type === "point" || i.type === "draw"));
        }

        return annotationTypes.filter(
            (i) => i.docTypes.indexOf(this.curFile.docTypeGroup) > -1
        );
      } else {
        return [];
      }
    },
  }
}
</script>

<style scoped>
.dropdown-menu{
  --bs-dropdown-link-hover-bg: #F4F4F6;
  --bs-dropdown-min-width: 114px;

  --bs-dropdown-link-active-color: #000000;
  --bs-dropdown-link-active-bg: #F4F4F6;

  min-width: 114px;
}
.dropdown-item:focus, .dropdown-item:hover {
  background-color: #F4F4F6;
}
.dropdown-item.active, .dropdown-item:active{
  color: #000000;
  background-color: #F4F4F6;
}
.dropdown.toolbar{
  z-index: 1000;
}
</style>