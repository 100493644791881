<script>
const _ = require('lodash');

export default {
  name: "proof-view-file-details",
  props: {
    file: {
      type: Object
    }
  },
  data() {
    return {};
  },
  methods: {
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    },
  },
  computed: {
    metadata() {
      return _.get(this.file, 'proofFileMetadata.fileMetadata', {});
    },
    fileName() {
      return _.get(this.file, 'name', 'N/A');
    },
    title() {
      return _.get(this.metadata, 'Title');
    },
    author() {
      return _.get(this.metadata, 'Author');
    },
    created() {
      return _.get(this.metadata, 'CreationDate');
    },
    modified() {
      return _.get(this.metadata, 'ModifiedDate');
    },
    size() {
      return _.get(this.metadata, 'FileSizeBytes');
    },
    width() {
      return _.get(this.metadata, 'Width');
    },
    height() {
      return _.get(this.metadata, 'Height');
    },
    filePageSizePx() {
      if (!this.width || !this.height) {
        return null;
      }
      return `${this.width} x ${this.height} px`;
    },
    fileSizeInches() {
      if (!this.width || !this.height) {
        return null;
      }
      return `${(this.width / this.pixelsToInches).toFixed(2)} x ${(this.height / this.pixelsToInches).toFixed(2)} in`;
    },
    pixelsToInches() {
      return 96;
    },
    fileSize() {
      return _.get(this.metadata, 'FileSizeBytes');
    },
    numberOfAvailableFields() {
      return [this.fileName, this.title, this.author, this.created, this.modified, this.size]
          .filter(f => f)
          .length;
    },
    readableFileSize() {
      if (!this.fileSize) {
        return null;
      }
      const i = Math.floor(Math.log(this.fileSize) / Math.log(1024));
      return `${(this.fileSize / Math.pow(1024, i)).toFixed(2)} ${['B', 'kB', 'MB', 'GB', 'TB'][i]}`;
    }
  }
}
</script>
<template>
  <ul class="list-unstyled d-flex flex-column gap-2 mb-0">
    <li class="p-10 border-gray-200" :class="{'border-bottom' : numberOfAvailableFields > 1}">
      <h6 class="text-gray-900 fw-semibold mb-1">{{ getLabel('review_screen_labels', 'file_name') }}</h6>
      <p class="text-gray-900 mb-0">{{ fileName }}</p>
    </li>
    <li class="p-10 border-bottom border-gray-200" v-show="title">
      <h6 class="text-gray-900 fw-semibold mb-1">{{ getLabel('review_screen_labels', 'file_title') }}</h6>
      <p class="text-gray-900 mb-0">{{ title }}</p>
    </li>
    <li class="p-10 border-bottom border-gray-200" v-show="author">
      <h6 class="text-gray-900 fw-semibold mb-1">{{ getLabel('review_screen_labels', 'file_author') }}</h6>
      <p class="text-gray-900 mb-0">{{ author }}</p>
    </li>
    <li class="p-10 border-bottom border-gray-200" v-if="fileSizeInches">
      <h6 class="text-gray-900 fw-semibold mb-1">{{ getLabel('review_screen_labels', 'size') }}</h6>
      <p class="text-gray-900 mb-0">{{ fileSizeInches }}</p>
    </li>
    <!-- TODO: add size per page when is available on fileMetaData-->
<!--    <li class="p-10 border-bottom border-gray-200" v-if="fileSizeInches">-->
<!--      <h6 class="text-gray-900 fw-semibold mb-1">{{ getLabel('review_screen_labels', 'file_page_size') }}</h6>-->
<!--      <p class="text-gray-900 mb-0">{{ fileSizeInches }}</p>-->
<!--    </li>-->
    <li class="p-10 border-bottom border-gray-200" v-show="created">
      <h6 class="text-gray-900 fw-semibold mb-1">{{ getLabel('review_screen_labels', 'file_created') }}</h6>
      <p class="text-gray-900 mb-0">{{ $A.FormatDateToString(created) }}</p>
    </li>
    <li class="p-10 border-bottom border-gray-200" v-show="modified">
      <h6 class="text-gray-900 fw-semibold mb-1">{{ getLabel('review_screen_labels', 'file_modified') }}</h6>
      <p class="text-gray-900 mb-0">{{ $A.FormatDateToString(modified) }}</p>
    </li>
    <li class="p-10" v-show="fileSize">
      <h6 class="text-gray-900 fw-semibold mb-1">{{ getLabel('review_screen_labels', 'file_size') }}</h6>
      <p class="text-gray-900 mb-0">{{ readableFileSize }}</p>
    </li>
  </ul>
</template>

<style scoped>
</style>
