<script>
import ProofViewEvent from "./proof-view-event";
import moment from "moment";

export default {
  name: "proof-view-events-panel",
  components: {ProofViewEvent},
  props: ["approvers", "reviewers", "users", "events", "usersColor", "activeUsers", "parentId"],
  data() {
    return {
      lastHide : null
    }
  },
  mounted() {
    this.updateTransparency();
  },
  methods : {
    getUser(userId) {
      const allUsers = [...this.approvers, ...this.reviewers, ...this.users];
      return allUsers.find(a => a.id === userId);
    },
    hide(index) {
      // hide only one
      // this.displayedEvents.splice(index, 1);
      //  hide all before this date
      this.lastHide = moment.now();
      this.events.forEach(e => {
        e.hidden = true;
      });
    },
    getOpacity(index, length) {
      if (index === 0 && length > 2) {
        return 0.25;
      } else if (index === 0 && length > 1 || index === 1 && length > 2) {
        return 0.5;
      } else {
        return 1;
      }
    },
    updateTransparency() {
      const container = this.$el;
      if (!container || !container.querySelectorAll) return;
      const elements = Array.from(container.querySelectorAll('.element'));
      const outside = elements.filter(e => e.getBoundingClientRect().top < container.getBoundingClientRect().top);
      outside.forEach(e => e.style.opacity = 0.25);
      const inside = elements.filter(e => e.getBoundingClientRect().top > container.getBoundingClientRect().top);
      const sortedElements = inside.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);
      sortedElements.forEach((element, index) => {
        element.style.opacity = this.getOpacity(index, sortedElements.length);
      });
    },
    scrollToBottom() {
      if (!this.displayedEvents.length) return;
      this.$nextTick(() => {
        const container = this.$el.querySelector('.events-wrapper');
        if (container) {
            container.scrollTo({
            top: container.scrollHeight,
            behavior: 'smooth'
          });
        }
      });
    }
  },
  computed : {
    displayedEvents() {
      const self = this;
      const events= this.events.map(e => {
        let id = e.approverId ||
            e.userId ||
            _.get(e, 'appEventObject.commentAnnotation.approverId') ||
            _.get(e, 'appEventObject.commentAnnotation.userId');
        const c = {
          ...e,
          user : self.getUser(id)
        }
        return c;
      })
          .filter(e => e.user && !e.hidden)
          .filter(e => {
            // debugger
            return !self.lastHide || moment.utc(e.createdAt).isAfter(self.lastHide)
          });

      return events;
    },
    maxHeight() {
      if (this.$refs.event_1) {
        return this.$refs.event_1[0].$el.clientHeight * 4 + 28;
      }
      return 72*3;
    }
  },
  watch: {
    events(newEvents, oldEvents) {
      if (newEvents.length > oldEvents.length) this.scrollToBottom();
    },
  }
}
</script>

<template>
  <div v-if="displayedEvents.length" class="events-container" :style="`max-height:${maxHeight+20}px`">
    <div class="close-event" @click="hide">
      <img src="/img/close.svg">
    </div>
    <div class="events-wrapper" :style="`max-height:${maxHeight}px`" @scroll="updateTransparency">
      <proof-view-event
          v-if="approvers && approvers.length"
          v-for="(event, index) in displayedEvents"
          :id="`event_${index+1}`"
          :ref="`event_${index+1}`"
          :event="event"
          :index="index+1"
          :num-events="displayedEvents.length"
          :users-color="usersColor"
          :active-users="activeUsers"
          :parent-id="parentId"
          v-on="$listeners"
          class="element"
          :style="{ opacity: getOpacity(index, displayedEvents.length), cursor:'pointer' }"
      />
    </div>
  </div>
</template>

<style>
.events-container {
  bottom : 0px;
  right: 0px;
  position: fixed;
  width: 256px;
  padding: 14px;
  z-index: 10000;
  background-color: transparent !important;
}

.events-wrapper {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.close-event {
  position: absolute;
  right: 24px;
  top: 0px;
  width: 24px;
  height: 24px;
  z-index: 1;
}

.close-event:hover {
  cursor: pointer;
}

</style>
