<script>
import ProofViewComment from "./proof-view-comment";

export default {
  name: "proof-view-comments",
  components: {ProofViewComment},
  props: ["label", "comments", "curFile", "activeCommentId", "curAnnotationType", "curUsersTaggable", "commentFileApi",
    "isLiveProofView", "liveProofActiveUrl", "liveProofSizeSelected",
    "usersColor", "curUser", "curApprover", "disabled", "activeEditorAnnotationId", "allowViewAllComments"],
  data() {
    return {
      showingComments: true,
      proofComments: [],
    };
  },
  methods: {
    showComments: function () {
      this.showingComments = true;
    },
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    },
    loadComments: function () {
      if (!this.activeEditorAnnotationId) {
        this.proofComments = this.comments;
      } else {
        let self = this;
        setTimeout(() => self.$nextTick(self.loadComments), 3000);
      }
    },
  },
  computed: {
    hasComments: function () {
      return _.get(this.proofComments, 'length', 0) > 0;
    }
  },
  watch: {
    showingComments: function (val) {
      this.$emit('on-showing-comments', val);
    },
    activeCommentId: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        const commentRef = this.$refs[`${this.label}-comment-${newVal}`];
        if (commentRef && commentRef.length) {
          commentRef[0].viewReplies(false);
        }
      }
    },
    comments: function () {
      this.$nextTick(this.loadComments);
    }
  }
}
</script>

<template>
  <div>
    <div v-show="!hasComments" class="px-4">
      <div class="p-3 rounded-3 bg-gray-50 text-center mb-4">
        <h6 class="text-gray-700 fs-14 mb-3 font-weight-bold">{{ getLabel('review_screen_labels', 'no_comments') }}</h6>
        <p class="fs-14 text-gray-700 mb-0">{{ getLabel('review_screen_labels', 'comments_instructions') }}</p>
      </div>
    </div>
    <div class="" v-show="hasComments">
      <div class="px-4 pb-3 pt-3 pt-lg-0">
        <div class="px-3 py-12 bg-gray-50 rounded-4">
          <div class="d-flex align-items-center ">
            <label class="form-check mb-0 p-0 fs-14 text-gray-900 form-switch d-flex gap-2 align-items-center">
              <input class="form-check-input d-none" type="checkbox" role="switch" id="comments-switch" v-model="showingComments">
              <span></span>
              <span class="text-normal font-weight-normal">{{ showingComments ? getLabel('review_screen_labels', 'comment_toggle') : getLabel('review_screen_labels', 'comment_toggle_hide')}}</span>
            </label>
          </div>
        </div>
      </div>

      <div :id="`${label}-comments-container`" class=" pb-4 comments-list d-flex flex-column">
        <proof-view-comment v-for="comment in proofComments"
                            :comment="comment"
                            :disabled="disabled"
                            :label="label"
                            :ref="`${label}-comment-${comment.id}`"
                            :is-active="comment.id === activeCommentId"
                            :cur-file="curFile"
                            :cur-user="curUser"
                            :cur-approver="curApprover"
                            :cur-annotation-type="curAnnotationType"
                            :cur-users-taggable="curUsersTaggable"
                            :comment-file-api="commentFileApi"
                            :is-live-proof-view="isLiveProofView"
                            :live-proof-active-url="liveProofActiveUrl"
                            :live-proof-size-selected="liveProofSizeSelected"
                            :users-color="usersColor"
                            :allow-view-all-comments="allowViewAllComments"
                            v-on="$listeners"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
</style>
