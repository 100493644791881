<script>
export default {
  name: "comment-actions",
  methods: {
    editComment() {
      this.$emit('on-edit-comment');
    },
    deleteComment() {
      this.$emit('on-delete-comment');
    },
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    },
  }
}
</script>

<template>

  <div class="ml-auto dropdown dropstart">
    <a data-toggle="dropdown" type="button">
      <img src="/img/review/icons/dots-vertical.svg" width="24" alt="">
    </a>
    <div class="dropdown-menu  bg-ashore-white box-shadow2xl rounded-3 py-1 border-0 w-auto">
      <ul class="list-unstyled mb-0 fs-14 ">
        <li>
          <a @click="editComment"
             class="d-flex dropdown-item text-decoration-none align-items-center text-gray-700 gap-3 py-2 px-3">
            <div class="icon">
              <img src="/img/review/icons/pencil.svg" width="20" alt="">
            </div>
            <span>{{ getLabel('review_screen_labels', 'edit_comment') }} </span>
          </a>
        </li>
        <li>
          <a @click="deleteComment"
             class="d-flex dropdown-item text-decoration-none align-items-center text-gray-700 gap-3 py-2 px-3">
            <div class="icon">
              <img src="/img/review/icons/trash.svg" width="20" alt="">
            </div>
            <span>{{ getLabel('review_screen_labels', 'delete_comment') }}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.dropdown-menu{
  --bs-dropdown-link-hover-bg: #F4F4F6;
  --bs-dropdown-min-width: 114px;

  --bs-dropdown-link-active-color: #000000;
  --bs-dropdown-link-active-bg: #F4F4F6;

  min-width: 114px;
}
.dropdown-item:focus, .dropdown-item:hover {
  background-color: #F4F4F6;
}
.dropdown-item.active, .dropdown-item:active{
  color: #000000;
  background-color: #F4F4F6;
}
</style>