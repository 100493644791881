<script>
import Vue from 'vue';
import ProofViewApprover from "./proof-view-approver";
import {BDropdown, BDropdownItem, BImg, BDropdownMenu} from 'bootstrap-vue';

export default {
  name: "proof-view-compare-navigation",
  components: {
    ProofViewApprover,
    BDropdown,
    BDropdownItem,
    BImg,
    BDropdownMenu,
  },
  props: ["avatar", "leftCurVersionId", "rightCurVersionId", "versions"],
  data() {
    return {
      isMobile: window.innerWidth < 994,
    };
  },
  mounted() {
    window.addEventListener('resize', this.refreshIsMobile);
    this.$emit('height-changed', this.$el.offsetHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.refreshIsMobile);
  },
  methods: {
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    },
    refreshIsMobile: function () {
      this.isMobile = window.innerWidth < 994;
    }
  },
  computed: {
  }
}


</script>

<template>
  <b-navbar
      class="navbar-expand-lg py-0 bg-body-tertiary bg-ashore-white border-bottom border-gray-100 flex-shrink-0 px-0" id="proofViewCompareNavigation">
    <b-container fluid :class="isMobile ? 'd-flex row m-auto align-items-center':'px-12'">
      <b-navbar-brand href="#" :class="isMobile ? 'col-sm-3 ml-3 p-0':''">
        <b-img :src="avatar" style="height: 40px;"></b-img>
      </b-navbar-brand>
      <b-nav
          :class="isMobile ? 'col-sm-5 flex-nowrap justify-content-around':'d-flex align-items-center gap-10 mr-lg-auto ml-lg-0 ml-auto py-10 py-lg-0'">
        <b-button variant="neutral" class="ml-2 border-0 font-weight-normal" style="background: #EBEBED" size="sm" @click="$emit('switch-to-review')">
          {{ getLabel('review_screen_labels', 'return_compare_btn') }}
        </b-button>
      </b-nav>
      <b-nav
          :class="isMobile ? 'col-sm-4': 'd-flex navbar-btn-container align-items-center align-self-stretch py-10 gap-3 px-lg-3 border-end border-gray-100 order-4 order-lg-3'">
        Compare
        <b-form-select :value="leftCurVersionId"
                       @change="value => $emit('on-left-version-change', value)"
                       :class="isMobile ? 'mr-3':'flex-shrink-0 w-auto pr-5'"
                       style="cursor: pointer;"
        >
          <b-form-select-option v-for="v in versions" :key="v.proofVersionId" :value="v.proofVersionId">
            v{{ v.versionNumber }}
          </b-form-select-option>
        </b-form-select>
      </b-nav>
      <b-nav
          :class="isMobile ? 'col-sm-4': 'd-flex navbar-btn-container align-items-center align-self-stretch py-10 gap-3 px-lg-3 border-end border-gray-100 order-4 order-lg-3'">
        To
        <b-form-select :value="rightCurVersionId"
                       @change="value => $emit('on-right-version-change', value)"
                       :class="isMobile ? 'mr-3':'flex-shrink-0 w-auto pr-5'"
                       style="cursor: pointer;"
        >
          <b-form-select-option v-for="v in versions" :key="v.proofVersionId" :value="v.proofVersionId">
            v{{ v.versionNumber }}
          </b-form-select-option>
        </b-form-select>
      </b-nav>
    </b-container>
  </b-navbar>
</template>


<style scoped>
.container-fluid {
  max-width: 100% !important;
  padding-right: 0;
  padding-left: 0;
}

::v-deep .btn-secondary, .btn-secondary:focus {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  color: #000;
}
</style>