<script>

export default {
  name: "proof-view-approver",
  props: {
    approvers: {
      type: Array,
      default: []
    },
    users: {
      type: Array,
      default: []
    },
    reviewers: {
      type: Array,
      default: []
    },
    actives: {
      type: Array,
      default: []
    },
  },
  data() {
    return {
      tooltip: false
    }
  },
  computed: {
    activeIds: function () {
      const ids = {};
      this.actives.forEach(u =>  ids[u.userId || u.approverId] = true);
      return ids;
    },
    activeNonApprovers() {
      return [...this.users, ...this.reviewers].filter(u => !!this.activeIds[u.id]);
    },
    approverInitials: function () {
      const seenApproverIds = {};
      const allApprovers = [...this.approvers, ...this.activeNonApprovers].
        filter(approver => {
          if (seenApproverIds[approver.id]) {
            return false;
          } else {
            seenApproverIds[approver.id] = true;
            return true;
          }
        });

      allApprovers.sort((a, b) => {
        if (this.activeIds[a.id] && !this.activeIds[b.id]) {
          return -1;
        } else if (!this.activeIds[a.id] && this.activeIds[b.id]) {
          return 1;
        } else {
          return 0;
        }
      });


      if (allApprovers.length > 10) {
        const visibleApprovers = allApprovers.slice(0, 10);
        const remainingApprovers = allApprovers.slice(10);
        return { visible: visibleApprovers, remaining: remainingApprovers };
      } else {
        return { visible: allApprovers, remaining: [] };
      }
    }
  },
  methods: {
    selected() {
      this.$emit('selected', this.id);
    },
    remainingApproversTooltip() {
      return this.approverInitials.remaining.map(approver => approver.name).join(', ');
    },
    isActive(id) {
      return !!this.actives.find(a => this.getId(a) === id);
    },
    getId(u) {
      return u.approverId || u.userId;
    },
    followApprover(approver) {
      if(this.isActive(approver.id)) {
          this.$emit('follow-approver', approver);
      }
    }
  },
  watch: {
    actives: function() {
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    }
  }
}
</script>

<template>
  <div class="avatar-group d-flex ml-auto">
    <div v-for="(approver, index) in approverInitials.visible" :key="index"
         :id="'approver-avatar-'+approver.id"
         :title="approver.name"
         :style="{ 'background-color': approver.color, 'color': 'white' }"
         :class="{ 'active': isActive(approver.id) }"
         @click="followApprover(approver)"
         v-b-tooltip.hover.bottom="approver.name"
         class="avatar fs-12 position-relative fw-semibold rounded-circle d-flex align-items-center justify-content-center">
      {{ approver.initials }}
      <div v-if="isActive(approver.id)" class="active-dot toolbar-active-dot rounded-circle bg-success position-absolute start-0 top-0"></div>
    </div>
    <div v-if="approverInitials.remaining.length > 0"
         :title="`+ ${approverInitials.remaining.length} remaining approver(s)`"
         id="remaining-approvers"
         class="avatar fs-12 position-relative fw-semibold rounded-circle d-flex align-items-center justify-content-center">
      +{{ approverInitials.remaining.length }}
      <b-tooltip :target="'remaining-approvers'" triggers="hover">
        <div v-for="(approver, index) in approverInitials.remaining" :key="index"
             :id="'approver-avatar-'+approver.id"
             :title="approver.name"
             :style="{ 'background-color': approver.color, 'color': 'white' }"
             :class="{ 'active': isActive(approver.id) }"
             @click="followApprover(approver)"
             v-b-tooltip.hover.right="approver.name"
             class="avatar fs-12 position-relative fw-semibold rounded-circle d-flex align-items-center justify-content-center">
          {{ approver.initials }}
          <div v-if="isActive(approver.id)" class="active-dot toolbar-active-dot rounded-circle bg-success position-absolute start-0 top-0"></div>
        </div>
      </b-tooltip>
    </div>
  </div>
</template>

<style scoped>
.avatar {
  /*cursor: pointer !important;*/
}
.avatar.active {
  cursor: grab !important;
}
</style>
