import { render, staticRenderFns } from "./proof-view-live-proof-comments.vue?vue&type=template&id=61815780&scoped=true&"
import script from "./proof-view-live-proof-comments.vue?vue&type=script&lang=js&"
export * from "./proof-view-live-proof-comments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61815780",
  null
  
)

export default component.exports