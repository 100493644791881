<script>
import ProofViewFileDetails from "./proof-view-file-details";
import ProofViewComments from "./proof-view-comments";
import ProofViewFreeUserPromo from "./proof-view-free-user-promo";

const DATE_PICKER = 'Datepicker';
export default {
  name: "proof-view-sidebar-right",
  components: {ProofViewComments, ProofViewFileDetails, ProofViewFreeUserPromo},
  props: ["label", "menu", "activeFile", "curAnnotations", "curAnnotationType",
    "activeCommentId", "curUsersTaggable", "commentFileApi",
    "isLiveProofView", "liveProofActiveUrl", "liveProofSizeSelected",
    "usersColor", "isCompareMode", "isMobile", "curUser", "curApprover",
    "avatar", "curAccount", "proofViewVersion", "disabled", "activeEditorAnnotationId", "allowViewAllComments", "currentProof"],
  data() {
    return {
      commentsVisible: true,
      fileDetailsVisible: false,
      thisProofVisible: false,
      DATE_PICKER
    };
  },
  mounted() {
    this.updateWidth();
  },
  updated() {
    this.updateWidth();
  },
  watch: {
  },
  methods: {
    toggle: function() {
      this.$emit('on-toggle');
    },
    updateWidth: function() {
      const sidebarWidth = this.$refs["right-sidebar"].offsetWidth;
      this.$emit('on-width-changed', sidebarWidth);
    },
    showComments: function(){
      this.commentsVisible = true;
    },
    getLabel: function (section, key) {
      return this.$A.LangService.getLabel(section, key);
    },
  },
  computed: {
    numComments: function() {
      return this.allowViewAllComments || this.curUser
      ? _.get(this.curAnnotations, 'length', 0)
      : _.filter(this.curAnnotations, ({ userId, approverId}) => 
      userId || approverId === _.get(this.curApprover, 'id', -1)).length;
    },
    customFields: function () {
      return _.get(this, 'currentProof.customFields', []);
    },
    hasCustomFieldValues: function () {
      return this.customFields.some(
        field => field.proofPropertyValue && field.proofPropertyValue.length > 0
      );
    },
  },
}
</script>

<template>
  <aside v-cloak
    :class="{'close': !menu, 'open' : menu, 'compare px-2' : isCompareMode || isMobile, 'sidebar' : !isMobile && !isCompareMode}"
    ref="right-sidebar" id="proofViewRightSidebar"
    class="border-start border-gray-100 flex-shrink-0 d-flex px-0 flex-column rounded-0">
    <div v-if="!isCompareMode && !isMobile"
      class="sidebar-header align-items-center border-bottom border-gray-100 px-4 py-3">
      <button class="menu-toggle btn p-0 btn-link d-flex align-items-center gap-1" @click="toggle">
        <img src="/img/review/icons/arrow-right.svg" class="arrow" width="20" alt="">
        <img src="/img/review/icons/menu-line.svg" class="line" width="2" alt="">
      </button>
    </div>
    <div v-cloak v-show="menu" x-transition :id="`${label}-right-bar`" class="sidebar-content flex-fill overflow-auto">
      <div class="d-flex flex-column h-100">
        <div class="accordion" id="accordionSidebar">
          <!-- This Proof -->
          <div class="accordion-item" v-if="currentProof">
            <h2 class="accordion-header position-relative">
              <button class="accordion-button d-flex align-items-center gap-10" type="button"
                :class="{ collapsed: !thisProofVisible }" @click="thisProofVisible = !thisProofVisible">
                <div class="icon">
                  <img src="/img/review/icons/chevron-right.svg" width="20" alt="">
                </div>
                This Proof
              </button>
            </h2>
            <b-collapse v-model="thisProofVisible" id="file-details-collapse" class="accordion-collapse"
              data-parent="#accordionSidebar">
              <div class="accordion-body px-4 py-3 border-top border-gray-100">
                <ul class="list-unstyled d-flex flex-column gap-2 mb-0">
                  <li class="p-10 border-bottom border-gray-200" v-if="currentProof && currentProof.name">
                    <h6 class="text-gray-900 fw-semibold mb-1">Proof Name</h6>
                    <p class="text-gray-900 mb-0">{{ currentProof.name }}</p>
                  </li>
                  <li :class="['p-10', { 'border-bottom border-gray-200': hasCustomFieldValues }]"
                    v-if="currentProof && currentProof.dueDate">
                    <h6 class="text-gray-900 fw-semibold mb-1">Due Date</h6>
                    <p class="text-gray-900 mb-0">{{ $A.FormatDateToString(currentProof.dueDate) }}</p>
                  </li>

                  <!-- Custom Fields -->
                  <li v-for="(field, index) in customFields"
                    v-if="field.proofPropertyValue && field.proofPropertyValue.length"
                    :class="['p-10', { 'border-bottom border-gray-200': index < customFields.length - 1 }]">
                    <h6 class="text-gray-900 fw-semibold mb-1">{{ field.accountProofPropertyLabel }}</h6>
                    <p class="text-gray-900 mb-0" v-for="value in field.proofPropertyValue">
                      {{ field.accountProofPropertyDataType === DATE_PICKER ? $A.FormatDateToString(value) : value }}
                    </p>
                  </li>
                </ul>
              </div>
            </b-collapse>
          </div>
          <!-- Files Details -->
          <div class="accordion-item">
            <h2 class="accordion-header position-relative">
              <button class="accordion-button d-flex align-items-center gap-10" type="button"
                      :class="{collapsed : !fileDetailsVisible}"
                      @click="fileDetailsVisible = !fileDetailsVisible">
                <div class="icon">
                  <img src="/img/review/icons/chevron-right.svg" width="20" alt="">
                </div>
                {{ getLabel('review_screen_labels', 'file_details_header') }}
              </button>
            </h2>
            <b-collapse v-model="fileDetailsVisible" id="file-details-collapse" class="accordion-collapse" data-parent="#accordionSidebar">
              <div class="accordion-body px-4 py-3 border-top border-gray-100">
                <proof-view-file-details :file="activeFile"/>
              </div>
            </b-collapse>
          </div>
          <!--Comments Details -->
          <div class="accordion-item border-bottom-0">
            <h2 class="accordion-header position-relative">
              <button class="accordion-button d-flex align-items-center gap-10" type="button"
                      :class="{collapsed : !commentsVisible}"
                      @click="commentsVisible = !commentsVisible">
                <div class="icon">
                  <img src="/img/review/icons/chevron-right.svg" width="20" alt="">
                </div>
                {{ getLabel('review_screen_labels', 'comment_toggle_header') }}
              </button>
              <div class="badge badge-gray position-absolute top-50 translate-middle-y end-0 mr-4">{{numComments}}</div>
            </h2>
            <b-collapse v-model="commentsVisible" id="comments-collapse" class="accordion-collapse" data-parent="#accordionSidebar">
              <div class="accordion-body p-0 ">
                <proof-view-comments
                    ref="comments"
                    :label="label"
                    :comments="curAnnotations"
                    :cur-file="activeFile"
                    :cur-user="curUser"
                    :cur-approver="curApprover"
                    :cur-annotation-type="curAnnotationType"
                    :active-comment-id="activeCommentId"
                    :cur-users-taggable="curUsersTaggable"
                    :comment-file-api="commentFileApi"
                    :is-live-proof-view="isLiveProofView"
                    :live-proof-active-url="liveProofActiveUrl"
                    :live-proof-size-selected="liveProofSizeSelected"
                    :users-color="usersColor"
                    :disabled="disabled"
                    :active-editor-annotation-id="activeEditorAnnotationId"
                    :allow-view-all-comments="allowViewAllComments"
                    v-on="$listeners"
                />
              </div>
            </b-collapse>
          </div>
        </div>
        <div class="flex-grow-1"></div>
        <!-- Free User Promo -->
        <proof-view-free-user-promo v-if="!isCompareMode && menu && curAccount.isFreeSku" :avatar="avatar" :cur-account="curAccount" :proof-view-version="proofViewVersion"/>
      </div>
    </div>
  </aside>
</template>

<style scoped>
/* Compare */
.compare {
  width: 100%;
  height: auto !important;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
}
.badge{
  padding: 2px 10px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 400;
  z-index: 100;
  line-height: 16px;
}
.badge-gray{
  background: #EBEBED;
  color: #252526;
}
.accordion-button:not(.collapsed) .icon{
  transform: rotate(90deg);
}
.accordion-button::after{
  display: none;
}
.accordion-item{
  border-bottom: 1px solid #EBEBED;
}
.accordion-item .accordion-button{
  position: relative;
  width: 100%;
  padding: 24px;
  font-size: 1rem;
  color: #575759;
  background-color: #F9F9F9;
  border: 0;
}
.accordion-header{
  margin-bottom: 0px;
}
.sidebar{
  width: 320px;
  background-color: #F9F9F9;
  transition: all ease-in-out 500ms;
  opacity: 1;
}
.sidebar.close .menu-toggle .arrow,
.sidebar.close .menu-toggle .line{
  transition: all ease-in-out 300ms;
}
.sidebar.close .menu-toggle .arrow{
  transform: scale(-1);
}
.sidebar.close .menu-toggle .line{
  order: -1;
}
@media(min-width:1200px){
  .sidebar.close{
    margin-right: -248px;
  }
}
@media(max-width:1200px) and (min-width:992px){
  .sidebar-header{
    display: flex !important;
  }
  .sidebar{
    position: fixed;
    right: 0;
    top: 60px;
    z-index: 10000;
    height: calc(100% - 60px);
  }
  .sidebar.close{
    right: -248px;
  }
}
@media(max-width:992px){
  .sidebar {
    position: static;
    width: 100%;
    height: auto !important;
  }
}
</style>
