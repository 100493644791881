class PointerUpdater {
  constructor(component) {
    this.userPointers = {}; // Stores user pointer history and target positions
    this.component = component;
    this.frameCount = 0; // Initialize frame count
    this.lastTime = Date.now(); // Initialize last time check
    this.initFPSLogger(); // Start the FPS logger
  }

  initFPSLogger() {
    setInterval(() => {
      const currentTime = Date.now();
      const elapsedTime = (currentTime - this.lastTime) / 1000; // Convert to seconds
      const fps = this.frameCount / elapsedTime; // Calculate FPS
      // console.log(`FPS: ${fps.toFixed(2)}`); // Log the FPS, rounded to two decimal places
      this.frameCount = 0; // Reset frame counter
      this.lastTime = currentTime; // Reset the last time check
    }, 1000); // Update every second
  }

  // Called with every mouse position update
  updateMousePosition(targetId, newX, newY, timestamp, drawCallback) {
    if (!this.userPointers[targetId]) {
      this.userPointers[targetId] = {
        positions: [],
        velocities: [],
        currentX: newX,
        currentY: newY,
        drawCallback: drawCallback,
        targetX: newX,
        targetY: newY,
        lastUpdateTime: 0
      };
      if (drawCallback) {
        drawCallback(newX, newY);
      }
    }

    let user = this.userPointers[targetId];
    user.targetX = newX; // Update target positions to new X
    user.targetY = newY; // Update target positions to new Y

    if (user.positions.length > 0) {
      const lastPos = user.positions[user.positions.length - 1];
      const dx = newX - lastPos.x;
      const dy = newY - lastPos.y;
      const dt = timestamp - lastPos.timestamp;
      if (dt > 0) { // Avoid division by zero
        const velocity = { vx: dx / dt, vy: dy / dt };
        user.velocities.push(velocity);
        if (user.velocities.length > 10) {
          user.velocities.shift(); // Keep the last 3 velocities
        }
      }
    }
    user.positions.push({ x: newX, y: newY, timestamp: timestamp });

    if (user.positions.length > 10) {
      user.positions.shift();
    }

    window.requestAnimationFrame((newTimestamp) => this.updateUsersPointer(targetId,newTimestamp));
  }

  updateUsersPointer(targetId, timestamp) {
    let user = this.userPointers[targetId];
    if (!user) return;

    if(user.lastUpdateTime === 0){
      user.lastUpdateTime = timestamp;
    }
    // Calculate the time elapsed since the last frame
    let elapsedTime = timestamp - user.lastUpdateTime;
    user.lastUpdateTime = timestamp;

    // Adjust the lerp factor based on elapsed time (consider tuning the multiplier)
    let dynamicLerpFactor = Math.min(1, 0.5 * (elapsedTime / (1000 / 60)));

    user.currentX += (user.targetX - user.currentX) * dynamicLerpFactor;
    user.currentY += (user.targetY - user.currentY) * dynamicLerpFactor;

    this.frameCount++;

    // Continuously update until the target is sufficiently close
    const distanceToTarget = Math.hypot(user.targetX - user.currentX, user.targetY - user.currentY);
    if (distanceToTarget > 1) { // Threshold to stop updating, can be adjusted
      if (user.drawCallback) {
        user.drawCallback(user.currentX, user.currentY);
      }
      window.requestAnimationFrame((newTimestamp) => this.updateUsersPointer(targetId, newTimestamp));
    }else{
      user.lastUpdateTime = 0;
    }
  }

  deleteUserPointer(targetId) {
    delete this.userPointers[targetId];
  }

  resetUserPointers() {
    this.userPointers = {};
  }

  // Smoothly updates the user pointer based on velocity
  // updateUsersPointer(userId) {
  //   let user = this.userPointers[userId];
  //   if (!user) return;
  //
  //   // Calculate average velocity (ensure this is done safely to avoid NaN values)
  //   let avgVelocity = { vx: 0, vy: 0 };
  //   if (user.velocities.length > 0) {
  //     avgVelocity = user.velocities.reduce((acc, cur) => ({ vx: acc.vx + cur.vx, vy: acc.vy + cur.vy }), avgVelocity);
  //     avgVelocity.vx /= user.velocities.length;
  //     avgVelocity.vy /= user.velocities.length;
  //   }
  //
  //   // Lerp towards the target position at the average velocity
  //   let lerpFactor = 0.01; // Adjust this value to control the smoothness
  //   user.currentX += (user.targetX - user.currentX) * lerpFactor;
  //   user.currentY += (user.targetY - user.currentY) * lerpFactor;
  //
  //   if (user.drawCallback) {
  //     user.drawCallback(user.currentX, user.currentY);
  //   }
  //
  //   // Continuously update until the target is sufficiently close
  //   const distanceToTarget = Math.hypot(user.targetX - user.currentX, user.targetY - user.currentY);
  //   if (distanceToTarget > 1) { // Threshold to stop updating, can be adjusted
  //     window.requestAnimationFrame(() => this.updateUsersPointer(userId));
  //   }
  // }
}

export default PointerUpdater;
