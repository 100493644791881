<script>

import { Chrome } from 'vue-color';
import redactor from "../form/redactor";
import { DOC_TYPES, ANNOTATION_TYPES } from '../../utils/constants';

const { AUDIO } = DOC_TYPES;
const { DRAW } = ANNOTATION_TYPES;

export default {
  name: "proof-view-comment-editor",
  components: {
    redactor,
    'chrome-picker': Chrome
  },
  props: ["comment", "saveLabel", "curAnnotationType", "curFile", "curUsersTaggable", "commentFileApi"],
  data() {
    return {
      colorPickerVisible: false,
      colorPickerColor: {hex8: "#D44D68"},
      drawToolLineWidth: 5,
      drawToolLineWidthOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20],
    };
  },
  mounted() {
    this.$emit('on-show');
  },
  destroyed() {
    this.$emit('on-hide');
  },
  methods: {
    toggleColorPicker: function () {
      this.colorPickerVisible = !this.colorPickerVisible;
    },
    undoDrawing: function () {
      this.$emit('on-undo-drawing');
    },
    resetDrawing: function () {
      this.$emit('on-reset-drawing');
    },
    setRecording: function (status) {
      this.$emit('on-set-recording', status);
    },
    saveComment: function () {
      let r = this.$refs.comment_redactor;
      let newText = r.getTextBody();
      r.setTextBody("");
      this.$emit('on-save-comment', this.comment, newText);
    },
    getText: function () {
      let r = this.$refs.comment_redactor;
      return r.getTextBody();
    }
  },
  computed: {
    showDrawToolsSection: function () {
      return _.get(this, 'curAnnotationType.type') === DRAW && _.get(this, 'curFile.docTypeGroup') !== AUDIO;
    }
  },
  watch: {
    colorPickerColor: function (newColor) {
      this.colorPickerColor = newColor;
      this.$emit('on-draw-color-change', newColor);
    },
    drawToolLineWidth: function (newWidth) {
      this.$emit('on-draw-line-width-change', newWidth);
    },
  }
}
</script>

<template>
  <div>
    <!-- draw tool ui-->
    <div v-show="showDrawToolsSection">
      <b-card class="rounded mb-2" id="drawing-tool-card">
        <!--  Undo + Clear -->
        <b-row class="d-flex justify-content-between mb-3 pr-0">
          <b-col col lg="5" md="5" sm="5">Draw Tool</b-col>
          <b-col col lg="6" md="6" sm="6" class="d-flex align-items-center justify-content-end ">
            <img src="/img/undo.svg" style="height: 16px;  cursor: pointer" @click="undoDrawing"
                 class="mr-md-3 ml-md-2 ml-lg-3">
            <img src="/img/reset.svg" style="height: 16px; cursor: pointer" @click="resetDrawing">
          </b-col>
        </b-row>
        <!-- Color Picker-->
        <div class="pl-0 pb-1">Color</div>
        <div class="d-flex mb-2 draw-wrapper">
          <b-col col lg="2" md="2" xs="2" class="p-0 circle-style">
            <span class="draw-colorpicker"
                  @click="toggleColorPicker"
                  :style="{'background-color': colorPickerColor.hex8 ? colorPickerColor.hex8 : colorPickerColor, minWidth: 15, minHeight:15, color: colorPickerColor.hex8 ? colorPickerColor.hex8 : colorPickerColor}"></span>
            <div v-show="colorPickerVisible" class="color-picker-modal">
              <chrome-picker v-model="colorPickerColor"></chrome-picker>
            </div>
          </b-col>
          <b-col @click="toggleColorPicker" col lg="8" md="8" xs="8"
                 class="align-items-center d-flex hex-color-text pl-0">{{
              colorPickerColor.hex8
            }}
          </b-col>
        </div>
        <!-- Stroke -->
        <div class="pl-0 pb-1">Stroke</div>
        <div>
          <b-col col lg="12" md="12" xs="10" class="draw-wrapper p-0">
            <v-select
                :options="drawToolLineWidthOptions"
                id="drawing-tool"
                class=""
                :clearable="false"
                v-model="drawToolLineWidth"
            >
              <!-- Selected Option -->
              <template slot="selected-option" slot-scope="option">
                <div class="draw-option-container">
                  <span class="draw-line" :style="{ height: option.label + 'px' }"></span>
                  <span class="font-weight-normal draw-line-option-label">{{ option.label }}px</span>
                </div>
              </template>

              <!-- Options in the Dropdown -->
              <template slot="option" slot-scope="option">
                <div class="draw-option-container">
                  <span class="draw-line" :style="{ height: option.label + 'px' }"></span>
                  <span class="font-weight-normal draw-line-option-label">{{ option.label }}px</span>
                </div>
              </template>
            </v-select>
          </b-col>
        </div>
      </b-card>
    </div>

    <!-- ends draw tool ui-->
    <redactor class="mb-2"
              :curUsersTaggable="curUsersTaggable"
              :commentApiUrl="commentFileApi"
              ref="comment_redactor"
              :show-source="false"
              :text="comment.text"
              @onrecording="setRecording"
    />
    <button class="btn btn-success w-100 fw-normal" @click="saveComment">{{ saveLabel }}</button>
  </div>
</template>

<style scoped>
.draw-wrapper {
  border: 1px solid #B5B5B8;
  border-radius: 6px;
  height: 40px;
}

.hex-color-text {
  font-family: "Open Sans";
  font-size: 16px;
  font-weight: 400;
}

::v-deep #drawing-tool-card .card-title {
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 400;
  color: #707073;
}

.draw-colorpicker {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.color-picker-modal {
  position: absolute;
  z-index: 3000;
  left: 0px;
  top: 40px;
}

::v-deep #drawing-tool-card .card-body {
  padding: 16px !important;
}

.border-draw-right {
  border-right: 1px solid #6A8B94;
}

.circle-style {
  display: flex;
  justify-content: center;
  align-items: center;
}

.draw-option-container {
  display: flex;
  align-items: center;
}

.draw-line {
  /*width: 20px;*/
  margin-right: 10px;
  background-color: black;
  width: 15px;
}

::v-deep .vs--single .vs__selected {
  margin-bottom: 15px !important;
}

::v-deep #drawing-tool .vs__actions {
  margin-bottom: 10px !important;
}


</style>
