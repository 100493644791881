<template>
  <div class="dropdown toolbar py-2 p-lg-0 px-3">
    <button @click="sizeDropdown= !sizeDropdown" data-toggle="dropdown"
            class="btn btn-toolbar w-100 text-gray-700 fw-normal border border-gray-300 d-flex align-items-center bg-ashore-white px-3 py-2 gap-2">
      <span class="font-weight-normal" x-text="selectValue">{{liveProofSizeSelected ? liveProofSizeSelected.label() : ''}}</span>
      <img src="/img/review/icons/chevron-down.svg" class="ml-auto" width="20" alt="">
    </button>
    <div class="dropdown-menu w-100 bg-ashore-white box-shadow3xl rounded-3 py-1 border-0 position-absolute top-full mt-1">
      <ul class="list-unstyled mb-0 fs-14 text-center">
        <li v-for="size in liveProofPredefinedSizes">
          <a @click="setLiveProofSizeSelected(size)"
             class="d-flex align-items-center gap-2 dropdown-item text-decoration-none text-gray-700 py-3 px-3">
            <span>{{size.label()}}</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "proof-view-live-proof-size",
  props : ["activeFile", "mode", "liveProofSizeSelected", "liveProofPredefinedSizes"],
  data : function () {
    return {
      sizeDropdown: false,
    }
  },
  methods: {
    setLiveProofSizeSelected: function(size) {
      this.$emit("set-live-proof-size-selected", size);
    },
  }
}
</script>

<style scoped>
.dropdown-menu{
  --bs-dropdown-link-hover-bg: #F4F4F6;
  --bs-dropdown-min-width: 114px;

  --bs-dropdown-link-active-color: #000000;
  --bs-dropdown-link-active-bg: #F4F4F6;

  min-width: 114px;
}
.dropdown-item:focus, .dropdown-item:hover {
  background-color: #F4F4F6;
}
.dropdown-item.active, .dropdown-item:active{
  color: #000000;
  background-color: #F4F4F6;
}
</style>