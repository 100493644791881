import { render, staticRenderFns } from "./proof-view-live-proof-size.vue?vue&type=template&id=d288de10&scoped=true&"
import script from "./proof-view-live-proof-size.vue?vue&type=script&lang=js&"
export * from "./proof-view-live-proof-size.vue?vue&type=script&lang=js&"
import style0 from "./proof-view-live-proof-size.vue?vue&type=style&index=0&id=d288de10&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d288de10",
  null
  
)

export default component.exports