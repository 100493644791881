<script>
export default {
  components: {},
  props: {
    publicProofId: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      id: this.$A.GetUUID(),
      approverName: "",
      approverEmail: "",
      message: "",
    };
  },
  updated: function () {},
  watch: {},
  mounted() {
    let self = this;
  },
  computed: {},
  methods: {
    open: function () {
      this.$bvModal.show(this.id);
    },
    register: async function () {
      if (this.approverName.length <= 1) {
        this.message = `${this.getLabel('identify_yourself_modal_labels', 'modal_name_error_msg')}`;
        return;
      }
      if (!this.$A.IsEmailValid(this.approverEmail)) {
        this.message = `${this.getLabel('identify_yourself_modal_labels', 'modal_email_error_msg')}`;
        // console.log('EMAIL', this.message)
        return;
      }
      this.message = "";
      this.approverName = this.approverName
        .toLowerCase()
        .replace(/\b[a-z](?=[a-z]{2})/g, function (letter) {
          return letter.toUpperCase();
        });
      let approver = await this.$A.ReviewService.RegisterApprover(
        this.$props.publicProofId,
        this.approverName,
        this.approverEmail
      );
      if (approver && approver.id > 0) {
        // console.log("registeredApprover",approver.approverProofToken.token)
        let savedIdentity = this.$A.ReviewService.SaveApproverInKeyChainIfDoesNotExistAndReturnIdentity(
          approver,
          this.$props.publicProofId
        );
        this.$emit("onapproverregistered", savedIdentity);
        this.$bvModal.hide(this.id);
      } else {
        this.message =
            `${this.getLabel('approver_decision_modal_labels', 'approver_error_alert')}`;
      }
    },
    getLabel: function(section, key) {
      return this.$A.LangService.getLabel(section, key);
    },
    handleKey: function(event) {
       if (event.code === 'Enter') {
         return this.register();
       }
    }
  },
};
</script>
<template>
  <div id="approver-registration-modal">
      <b-modal :id="id" size="lg" no-close-on-backdrop no-close-on-esc>
      <template v-slot:modal-header data-cy="approverRegistrationModal">
        <div class="w-100 text-center">
          <h4>{{ getLabel('identify_yourself_modal_labels', 'identify_yourself_header') }}</h4>
          <p>{{ getLabel('identify_yourself_modal_labels', 'identify_yourself_before_proofing') }}</p>
          <div class="form-group">
            <div class="form-item">
              <input
                class="form-control"
                :placeholder="getLabel('identify_yourself_modal_labels','identify_yourself_name_placeholder')"
                v-model="approverName"
                data-cy="approverNameInput"
                @keyup="handleKey"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="form-item">
              <input
                class="form-control"
                :placeholder="getLabel('identify_yourself_modal_labels','identify_yourself_email_placeholder')"                v-model="approverEmail"
                data-cy="approverEmailInput"
                @keyup="handleKey"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="form-item">
              <p style="color: red">{{ message }}</p>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:modal-footer="{ cancel }">
        <div class="w-100 d-flex justify-content-end align-items-center">
          <b-button
            class="green-btn"
            data-cy="approverBeginReviewBtn"
            @click="register()"
            >{{getLabel('identify_yourself_modal_labels', 'begin_review_btn')}}</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<style scoped>

.green-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 17px;
  height: 38px;
  background: #019256;
  box-shadow: 0px 1px 2px rgb(8 11 13 / 5%);
  border-radius: 6px;
  border-radius: 6px;
  font-family: 'Open Sans';
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  margin-left: 20px;
  padding: 10px !important;
}

.green-btn:hover {
  background-color: #019255 !important;
  opacity: 0.9 !important;
  color: white;
}



</style>
