<script>
import moment from "moment";

export default {
  name: "proof-view-event",
  components: {},
  props: ["event", "index", "numEvents", "usersColor", "activeUsers", "parentId"],
  computed: {
    prefixId: function () {
      return this.parentId ? this.parentId + "-" : "";
    },
    a() {
      return this.event.commentAnnotation;
    },
  },
  methods: {
    getColor: function (c) {
      return this.usersColor[c.id];
    },
    minutesSince(date) {
      return moment.utc().diff(moment.utc(date), 'minutes');
    },
    isActive(event) {
      const id = event.userId || event.approverId || event.user.id;
      return !!this.activeUsers.find(a => a.userId === id || a.approverId === id);
    },
    clicked() {
      if (this.event.appEventType === 34) {
        this.$emit("version-selected", this.event.proofVersionCreated.id);
        return;
      }
      const annotationId = _.get(this, 'a.id'); 
      if (document.getElementById(`${this.prefixId}comment-${annotationId}`)) {
        setTimeout(() => {
          this.$scrollTo(`#${this.prefixId}comment-${annotationId}`, 500, {
            container: `#${this.prefixId}right-bar`,
          });
        }, 100);
      }
      this.$emit("annotation-selected", this.a);
    }
  },
};
</script>

<template>
  <div @click="clicked" class="event" :class="`event-${numEvents-index}`">
    <!-- Avatar + User Name + Time -->
    <div class="row mx-0">
      <div class="d-flex align-items-center justify-content-start">
        <!-- Avatar -->
        <div :style="{'background-color': getColor(event.user)}"
             class="avatar fs-12 position-relative fw-semibold text-white rounded-circle d-flex align-items-center justify-content-center position-relative">
          {{ event.user.initials }}
          <div v-if="isActive(event)"
               class="active-dot toolbar-active-dot rounded-circle bg-success position-absolute start-0 top-0"></div>
        </div>
        <!-- User Name -->
        <h6 class="text-gray-900 mb-0 font-weight-normal text-break ml-2 pl-2"
            style="font-size: 14px; font-family: 'Open Sans'">{{ event.user.name }}</h6>
      </div>
      <div class="ml-auto text-gray-500">
        <div>
          <span v-if="minutesSince(event.triggeredAt) === 0">Now</span>
          <span v-else>{{ minutesSince(event.triggeredAt) + 'm' }}</span>
        </div>
      </div>
    </div>
    <!-- Event Info -->
    <div class="flex-row mx-0">
      <span class="text-gray-500 fs-12 font-weight-normal mb-0 mt-1 ml-3 pl-4"
            style="font-size: 14px; font-family: 'Open Sans';">{{ event.event }}</span>
    </div>
  </div>
</template>


<style scoped>

.event {
  padding: 8px 16px 8px 16px;
  border-radius: 4px;
  position: relative;
  background: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(213, 213, 216, 1);
}
</style>
